<template>
  <div class="wrapper">
    <!-- ========== Left Sidebar Start ========== -->
    <div class="leftside-menu mt-0">
      <div class="p-2 text-center">
        <button
          class="btn btn-outline-primary"
          @click.prevent="showFundsOfFunds"
        >
          Show Funds of Funds
        </button>
        <button
          class="m-2 btn btn-outline-primary"
          @click.prevent="warmIntroductions"
        >
          Warm Introductions
        </button>
      </div>
      <div class="p-3 pt-0">
        <div>
          <h4 class="accordion-header pt-0 pb-2">
            Filter Astel Match for {{startup.name}}
          </h4>
          <div>
            <div class="accordion-body">
              <div class="p-0">
                <div class="mb-3 d-flex">
                  <button class="btn btn-outline-primary flex-grow-1" @click.prevent="doContactSearch">
                    <i class="uil uil-redo"></i>
                    Refresh Results
                  </button>
                </div>

                <div class="mb-4">
                  <h4 class="mb-1">Breakdown:</h4>
                  <div class="mb-2">
                    <v-select
                        name="breakdown_field"
                        v-model="localBreakdownField"
                        :options="breakdownFieldOptions"
                        label="label"
                        placeholder="Select field"
                        :clearable="true"
                    >
                    </v-select>
                  </div>
                  <div class="mb-2" v-if="localBreakdownField && localBreakdownField.id == 'investment_countries'">
                    <div v-for="(group, index) in breakdownGroups" class="d-flex align-items-center">
                      <v-select
                          class="flex-grow-1"
                          name="breakdown_groups"
                          v-model="group.value"
                          :options="optionsCountries"
                          label="name"
                          multiple
                          @option:selected="onBreakdownGroupsChange"
                          :close-on-select="true"
                      >
                      </v-select>
                      <a href="#" class="ui uil-trash-alt ms-1" @click.prevent="deleteBreakdownGroup(group, index)" title="Delete breakdown group"></a>
                    </div>
                  </div>
                  <div class="mb-2" v-if="localBreakdownField && localBreakdownField.id == 'investor_region'">
                    <div v-for="(group, index) in breakdownGroups" class="d-flex align-items-center">
                      <v-select
                          class="flex-grow-1"
                          name="breakdown_groups"
                          v-model="group.value"
                          :options="optionsRegions"
                          label="name"
                          multiple
                          @option:selected="onBreakdownGroupsChange"
                          :close-on-select="true"
                      >
                      </v-select>
                      <a href="#" class="ui uil-trash-alt ms-1" @click.prevent="deleteBreakdownGroup(group, index)" title="Delete breakdown group"></a>
                    </div>
                  </div>
                  <div class="mb-2" v-if="localBreakdownField && localBreakdownField.id == 'investor_type'">
                    <div v-for="(group, index) in breakdownGroups" class="d-flex align-items-center">
                      <v-select
                          class="flex-grow-1"
                          name="breakdown_groups"
                          v-model="group.value"
                          :options="optionsInvestorTypes"
                          label="name"
                          multiple
                          @option:selected="onBreakdownGroupsChange"
                          :close-on-select="true"
                      >
                      </v-select>
                      <a href="#" class="ui uil-trash-alt ms-1" @click.prevent="deleteBreakdownGroup(group, index)" title="Delete breakdown group"></a>
                    </div>
                  </div>
                  <div>
                    <a href="#" @click.prevent="addBreakdownGroup">+ Add Custom Group</a>
                  </div>
                  <v-select
                      class="flex-grow-1 mt-4"
                      name="breakdown_groups"
                      v-model="localBreakdownTier"
                      placeholder="Select Tier"
                      :reduce="x => x.id"
                      :options="breakdownTierOptions"
                      :close-on-select="true"
                  >
                  </v-select>
                </div>

                <h4 class="mb-0">Filter results:</h4>
                <div class="mt-1 d-flex">
                  <button class="btn btn-outline-primary flex-grow-1" @click.prevent="onMatchInvestors()">
                    <i class="uil uil-filter"></i>
                    Match Investors
                  </button>
                </div>
                <div class="small pt-1">
                  <p class="mb-0 text-center">Apply filters based on startup profile.</p>
                </div>
              </div>

              <div class="p-0">
                <form class="g-3 align-items-center form-side-bar" @submit.prevent="doContactSearch">
                  <div class="row">
                    <div v-for="(group, gIndex) in form" :key="group" class="p-0">
                      <div class="card search-field-group p-2 mb-2">
                        <div v-for="(item, index) in group.fields" :key="index">
                          <v-select
                              name="field"
                              v-model="item.field"
                              :options="searchFields"
                              label="field_label"
                              placeholder="Select field"
                              @option:selected="onFieldChange(item)"
                          >
                          </v-select>
                          <v-select
                              v-if="item.field"
                              name="condition"
                              v-model="item.condition"
                              :options="item.field.conditions"
                              label="name"
                          >
                          </v-select>
                          <template v-if="item.field">
                            <template v-if="item.field.field_type === 'string'">
                              <v-select
                                  name="filter_value"
                                  v-model="item.value"
                                  :options="tagOptions"
                                  @search="searchTags"
                                  multiple
                                  taggable
                                  :close-on-select="false"
                              >
                              </v-select>
                            </template>
                            <template v-if="item.field.field_type === 'integer'">
                              <input
                                  id="id_filter_value"
                                  type="number"
                                  name="filter_value"
                                  class="form-control"
                                  v-model="item.value"
                              >
                            </template>
                            <template v-if="item.field.field_type === 'choicefield'">
                              <v-select
                                  name="filter_value"
                                  v-model="item.value"
                                  :options="item.field.options.map((x) => ({ id: x[0], label: x[1] }))"
                                  :reduce="(x) => x.id"
                                  multiple
                              >
                              </v-select>
                            </template>
                            <template v-if="item.field.field_type === 'stringchoicefield'">
                              <v-select
                                  name="filter_value"
                                  v-model="item.value"
                                  :options="item.field.options.map((x) => ({ id: x[0], label: x[1] }))"
                                  :reduce="(x) => x.label"
                                  multiple
                                  taggable
                                  :close-on-select="false"
                              >
                              </v-select>
                            </template>
                            <template v-if="item.field.field_type === 'foreignkey'">
                              <v-select
                                  name="filter_value"
                                  v-model="item.value"
                                  :options="item.field.options.map((x) => ({ id: x[0], label: x[1] }))"
                                  :reduce="(x) => x.id"
                                  multiple
                                  :close-on-select="false"
                              >
                              </v-select>
                            </template>
                            <template v-if="item.field.field_type === 'city'">
                              <v-select
                                  name="filter_value"
                                  v-model="item.value"
                                  :options="optionsCities"
                                  @search="(search, loading) => { this.searchCities(item, search, loading) }"
                                  :reduce="x => x.id"
                                  label="name"
                                  multiple
                                  :close-on-select="false"
                              >
                              </v-select>
                            </template>
                            <template v-if="item.field.field_type === 'country'">
                              <v-select
                                  name="filter_value"
                                  v-model="item.value"
                                  :options="optionsCountries"
                                  :reduce="x => x.id"
                                  label="name"
                                  multiple
                                  :close-on-select="false"
                              >
                              </v-select>
                            </template>
                            <template v-if="item.field.field_type === 'tag'">
                              <v-select
                                  name="filter_value"
                                  v-model="item.value"
                                  :options="tagOptions"
                                  @search="searchTags"
                                  multiple
                                  taggable
                                  :close-on-select="false"
                                  :clear-search-on-select="false"
                              >
                              </v-select>
                            </template>
                          </template>

                          <div class="float-end">
                            <a href="#" class="ui uil-trash-alt me-1" @click.prevent="deleteField(group, index, gIndex)" title="Delete this field"></a>
                          </div>

                          <div v-if="group.fields.length > 1" class="d-flex justify-content-center my-2">
                            <template v-if="index === 0">
                              <div class="form-check mx-2">
                                <input class="form-check-input" type="radio" :id="`group_operator_${gIndex}_${index}_and`" v-model="group.operator" value="AND">
                                <label class="form-check-label" :for="`group_operator_${gIndex}_${index}_and`">
                                  AND
                                </label>
                              </div>
                              <div class="form-check mx-2">
                                <input class="form-check-input" type="radio" :id="`group_operator_${gIndex}_${index}_or`" v-model="group.operator" value="OR">
                                <label class="form-check-label" :for="`group_operator_${gIndex}_${index}_or`">
                                  OR
                                </label>
                              </div>
                            </template>
                            <template v-else-if="index + 1 !== group.fields.length">
                              {{ group.operator }}
                            </template>
                          </div>
                        </div>

                        <div class="mt-2">
                          <a href="#" @click.prevent="addField(group)">+ Add Field</a>
                        </div>

                      </div>
                      <div v-if="gIndex != form.length - 1" class="text-center pb-2">
                        <span>AND</span>
                      </div>
                    </div>

                    <div class="mt-2 d-flex">
                      <a href="#" class="btn btn-outline-primary flex-grow-1" @click.prevent="addFilter">
                        <i class="uil uil-filter"></i>
                        Add Filter
                      </a>
                    </div>

                    <div class="mt-4 d-flex">
                      <a href="#" class="btn btn-outline-danger flex-grow-1" @click.prevent="clearFilters">
                        <i class="uil uil-trash"></i>
                        Remove All Filters
                      </a>
                    </div>

                    <div class="mt-4">
                      <hr class="mb-3">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="default_exclude" v-model="default_exclude">
                        <label class="form-check-label" for="default_exclude">Exclude unsuitable investor types</label>
                      </div>
                    </div>

                    <div class="mt-1 mb-4">
                      <hr class="mb-3">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="hide_ring_fence" v-model="hide_ring_fence">
                        <label class="form-check-label" for="hide_ring_fence">Hide Ringfence</label>
                      </div>
                    </div>
                    <div class="mt-1 mb-4">
                      <hr class="mb-2">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="hide_undeliverable_contact" v-model="hide_undeliverable_contact">
                        <label class="form-check-label" for="hide_undeliverable_contact">Hide Undeliverable Contacts</label>
                      </div>
                    </div>
                    <div class="mb-3 d-flex">
                      <button class="btn btn-outline-primary flex-grow-1" @click.prevent="doContactSearch">
                        <i class="uil uil-redo"></i>
                        Apply Filters
                      </button>
                    </div>


                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>
      <!-- Sidebar -left -->

    </div>
    <!-- Left Sidebar End -->

    <div class="content-page">
      <div class="content">
        <div class="row">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <ul class="nav nav-tabs" v-if="matchFields && matchFields.length">
                    <li class="nav-item" v-for="group in matchFields">
                      <a
                        href="#"
                        class="nav-link"
                        :class="{ active: matchGroup === group.name }"
                        @click.prevent="addMatchFilters(group.name)"
                      >
                        {{ group.name }}
                      </a>
                    </li>
                  </ul>
                  <div class="refresh-container" v-if="filtersChanged || contactsLoading">
                    <template v-if="filtersChanged">
                      <p style="text-align: center; margin-bottom: 10px;">Filters have changed</p>
                      <button class="btn btn-primary btn-sm " @click.prevent="doContactSearch">
                        <i class="uil uil-redo"></i>
                        Refresh Result
                      </button>
                    </template>
                    <div v-else-if="contactsLoading" class="d-flex flex-column align-items-center">
                      <loader></loader>
                      <p>Our AI Analyst is evaluating your deal and identifying the best matches for you</p>
                    </div>
                  </div>
                  <div :class="{'blurred': filtersChanged || contactsLoading}" class="table-data">
                    <div class="table-container">
                      <table class="table table-hover">
                        <thead>
                        <tr>
                          <th class="orderable" style="width: 15%">
                            Full name
                          </th>
                          <th class="orderable" style="width: 15%">
                            Company
                          </th>
                          <th class="orderable" style="width: 15%">
                            Score
                          </th>
                          <th class="orderable" style="width: 15%">
                            Career Match
                          </th>
                          <!-- <th style="width: 70%">
                            Insights
                          </th> -->
                        </tr>
                        </thead>
                        <template v-if="contactsLoading">
                          <tbody>
                          <tr>
                            <td colspan="4">
                              <div class="d-flex flex-column align-items-center">
                                <loader size="48px"></loader>
                                <p>Our AI Analyst is evaluating your deal and identifying the best matches for you</p>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </template>
                        <template v-else>
                          <tbody v-if="contacts && contacts.results">
                          <tr v-for="contact in contacts.results" :key="contact.id">
                            <td>
                              <router-link :to="`/contacts/${contact.id}/`">
                                {{ contact.first_name }} <span class="blur-md">LastName</span>
                              </router-link>
                              <span class="mx-2">
                                <i class="uil uil-user text-green" v-if="contact.met_in_person"></i>
                                <i class="uil uil-phone text-green" v-if="contact.over_call"></i>
                                <i class="uil uil-envelope text-green" v-if="contact.over_email"></i>
                              </span>
                              <p class="small text-muted">
                                <span class="blur-md">{{ contact.first_name }}{{ contact.id }}@example.net</span>
                              </p>
                            </td>
                            <td>
                              {{ contact.company_name || '—' }}
                            </td>
                            <td>
                              <div v-if="contact.matching_score">
                                {{ contact.matching_score }}%
                              </div>
                              <div v-else>
                                --
                              </div>
                            </td>
                            <td>
                              {{ contact.career_email_opener || '—' }}
                            </td>
                            <!-- <td>
                              <div class="py-2" v-if="contact.insights_loading">
                                <loader size="48px"></loader>
                              </div>
                              <div v-if="Array.isArray(contact.insights) && contact.insights.length">
                                <ul>
                                  <li v-for="item in contact.insights">
                                    {{ item.content }}<br>
                                    <span class="small" v-if="item.link">
                                        [via <a :href="item.link" target="_blank">{{ getDomainFromURL(item.link) }}</a>]
                                      </span>
                                  </li>
                                </ul>as
                              </div>
                              <div v-else-if="!contact.insights_loading">
                                --
                              </div>
                            </td> -->
                          </tr>
                          </tbody>
                        </template>
                      </table>

                      <pagination
                          :items="contacts"
                          :currentPage="page"
                          :perPage="perPage"
                          @pagechanged="onPageChanged"
                      >
                      </pagination>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div> <!-- end containt-page -->
    </div>

    <div class="action-footer">
      <div class="container-fluid">
        <div class="card mb-0">
          <div class="card-body">
            <div class="float-end">

              <span class="me-2">Save search:</span>

              <div class="form-group d-inline me-4">
                <input type="text" v-model="searchName" placeholder="Search name">
              </div>

              <button class="btn btn-primary" :class="{ disabled: isSubmitting || isLoading || contactsLoading }" @click.prevent="saveSearch">
                <i class="uil uil-notebooks"></i>
                Save Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AstelSearchMixin from '@/mixins/astelSearch'
import Swal from 'sweetalert2'
import {isInitialLoadOrReload} from '@/utils'

export default {
  name: 'astel_campaigns',

  mixins: [AstelSearchMixin],

  data() {
    return {
      page: 1,
      perPage: 20,
      isSubmitting: false,
      pollTimer: null,
      module: 'astel_campaigns',
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      contactsLoading: state => state.astelInvestors.contactsLoading,
    }),
    loadingInsights() {
      return this.contacts && this.contacts.results && this.contacts.results.some((x) => x.insights_loading)
    }
  },

  beforeUnmount() {
    if (this.pollTimer) {
      clearTimeout(this.pollTimer)
    }
  },

  created(){
    this.$nextTick(() => {
      if (isInitialLoadOrReload()) {
        this.resetSharedState();
      }
    });
  },

  mounted() {
    if (this.user && !this.user.is_staff) {
      this.$router.push({ name: 'startup_profile' })
      return
    }
    this.searchName = `Astel Match ${this.startup.name}`

    Promise.all([
      this.$store.dispatch('LOAD_ASTEL_SEARCH_FIELDS'),
    ]);

  },

  methods: {

    selectCountry(country){
      return Promise.all([
        this.$store.commit('SET_SELECTED_ASTEL_COUNTRY', country),
        this.doSearch()
      ])
    },

    submitRequestAccess(){
      return Promise.all([
        this.$store.dispatch('SUBMIT_ASTEL_NETWORK_ACCESS_REQUEST', {
          countries: this.selectedCountry.code,
          startup: this.startup.id
        }),
        Swal.fire({
          title: "Thank you for showing interest!",
          text: "A representative from Astel Network team will reach out to you for the  next steps!",
          icon: "success"
        })
      ])
    },

    isActive(groupName) {
      if (this.matchGroup === null || this.matchGroup === undefined) {
        return false
      } else {
        return this.matchGroup.trim() === groupName.trim();
      }
    },

    isSelectedCountry(country) {
      return this.selectedCountry && this.selectedCountry.id === country.id;
    },

    loadStartupContent() {
      return Promise.all([
        this.$store.dispatch('LOAD_ASTEL_MATCH_FIELDS', { startupId: this.startup.id }),
        this.$store.dispatch('LOAD_SEARCHES', { startupId: this.startup.id }),
      ])
    },

    onMatchInvestors() {
      this.loadStartupContent().then(() => {
        this.$store.commit('SET_CURRENT_SEARCH', null)
        this.addMatchFilters()
        this.localBreakdownField = null
        this.localBreakdownTier = null
        this.doContactSearch()
      })
    },

    onPageChanged(pageNumber) {
      this.page = pageNumber
      this.doContactSearch()
    },

    saveSearch() {
      if (!this.startup) { return }
      const query = this.makeQuery()
      const search = {
        search_request_data: query,
        startup_id: this.startup.id,
        default_exclude: this.default_exclude || false,
        hide_ring_fence: this.hide_ring_fence || false,
        hide_undeliverable_contact: this.hide_undeliverable_contact || false,
        save_search_name: this.searchName || 'Untitled',
        number_of_investors: this?.companies?.count || 0,
        number_of_contacts: this.contacts?.count,
        tier: this.localBreakdownTier ? this.localBreakdownTier : null,
        is_career_match: this.isCareerMatchesSearch,
        is_best_match: this.isBestMatchesSearch,
        is_astel_search: true,
        selected_match_group: this.matchGroup,
        breakdown: this.breakdownField,
      }
      this.isSubmitting = true;
      this.$store.dispatch('SAVE_SEARCH', { form: search, startupId: this.startup.id }).then((response) => {
        this.$store.commit('SET_CURRENT_SEARCH', response.data)
        this.$store.dispatch('LOAD_SEARCHES', { startupId: this.startup.id }).then(() => {
          this.$router.push({ name: 'investors_searches' })
        })
      }).finally(() => {
        this.isSubmitting = false
      })
    },

    getDomainFromURL(url) {
      if (!url) return ''
      const domain = url.replace('http://', '').replace('https://', '').split(/[/?#]/)[0]
      return domain
    },
  }

}
</script>
