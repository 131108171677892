
import axios from 'axios'
import SparkMD5 from 'spark-md5'

const state = {
  companies: null,
  contacts: null,
  searchHash: '',
  searchHash: '',
  companiesHash: '',
  companiesLoading: false,
  companiesLoaded: false,
  companiesLoaded: false,
  contactsHash: '',
  contactsLoading: false,
  contactsLoaded: false,
  searches: null,
  currentSearch: null,
  investorTypes: [],
  investmentStages: [],
  oneLiner: [],
  cities: [],
  countries: [],
  titles: [],
  regions: [],
  contactTypes: [],
  searchFields: [],
  searchForm: [],
  searchOptions: {
    default_exclude: true,
    hide_ring_fence: true,
    hide_undeliverable_contact: true
  },
  breakdownOptions: {
    field_name: 'investment_countries',
    default: true,
    custom_groups: []
  },
  matchFields: [],
  matchGroup: '',
  jobs: [],
  currentSearchCancelToken: null,
  activeSearchCount: 0,
  areWarmIntroductions: false,
  isCareerMatchesSearch: false,
  isBestMatchesSearch: false,
  breakdownField: {},
  breakdownTier: null,
}

const actions = {
  LOAD_ASTEL_SEARCH_OPTIONS ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      Promise.all([
        axios.get(`${import.meta.env.VITE_API_URL}options/investortypes/`).then((response) => {
          commit('SET_ASTEL_INVESTOR_TYPES', response.data)
        }),
        axios.get(`${import.meta.env.VITE_API_URL}options/investmentstages/`).then((response) => {
          commit('SET_ASTEL_INVESTMENT_STAGES', response.data)
        }),
        axios.get(`${import.meta.env.VITE_API_URL}options/countries/`).then((response) => {
          commit('SET_ASTEL_COUNTRIES', response.data)
        }),
        axios.get(`${import.meta.env.VITE_API_URL}options/titles/`).then((response) => {
          commit('SET_ASTEL_TITLES', response.data)
        }),
        axios.get(`${import.meta.env.VITE_API_URL}regions/`).then((response) => {
          commit('SET_ASTEL_REGIONS', response.data)
        }),
        axios.get(`${import.meta.env.VITE_API_URL}options/oneliner/`).then((response) => {
          commit('SET_ASTEL_ONELINER', response.data)
        }),
      ])
        .then(resolve)
        .catch(reject)
    })
  },

  LOAD_ASTEL_CONTACTS ({ commit, dispatch, state }, startupId) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}contacts/startup/astel/${startupId}/`, { startupId })
        .then((response) => {
          // commit('SET_CONTACTS', response.data);
          commit('SET_ASTEL_CONTACTS', response.data)
          commit('SET_ASTEL_CONTACTS_LOADING', false)
          commit('SET_ASTEL_CONTACTS_LOADED', true)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  LOAD_ASTEL_COUNTRIES ({ commit, dispatch, state }, startupId) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}contacts/startup/astel/${startupId}/stats/`, { startupId })
        .then((response) => {
          commit('SET_ASTAL_COUNTRIES', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  ASTEL_SEARCH ({ commit, dispatch, state }, { form, params, startupId }) {
    // Cancel the previous request if it exists
    if (state.currentSearchCancelToken && typeof state.currentSearchCancelToken.cancel === 'function') {
      state.currentSearchCancelToken.cancel('Operation canceled due to new request.')
    }

    // Create a new CancelToken
    const cancelToken = axios.CancelToken.source()
    commit('SET_ASTEL_CURRENT_SEARCH_CANCEL_TOKEN', cancelToken)

    const hash = SparkMD5.hash(JSON.stringify(form) + JSON.stringify(params) + startupId)
    commit('SET_ASTEL_SEARCH_HASH', hash)

    // Increment active search count and set loading states
    commit('INCREMENT_ASTEL_ACTIVE_SEARCH_COUNT')
    commit('SET_ASTEL_CONTACTS_LOADING', true)
    commit('SET_ASTEL_CONTACTS_LOADED', false)
    commit('SET_ASTEL_COMPANIES_LOADING', true)
    commit('SET_ASTEL_COMPANIES_LOADED', false)

    return new Promise((resolve, reject) => {
      axios.post(
        `${import.meta.env.VITE_API_URL}astel/search/${startupId}/`,
        form,
        {
          params,
          paramsSerializer: {
            indexes: null
          },
          cancelToken: cancelToken.token
        }
      )
        .then((response) => {
          if (hash === state.searchHash) {
            commit('SET_ASTEL_CONTACTS', response.data.contacts)
            commit('SET_ASTEL_COMPANIES', response.data.companies)
            commit('SET_ASTEL_CONTACTS_LOADED', true)
            commit('SET_ASTEL_COMPANIES_LOADED', true)
          }
          resolve(response)
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            console.log('Request canceled:', error.message)
          } else {
            console.log(error)
            reject(error)
          }
        })
        .finally(() => {
          // Decrement active search count
          commit('DECREMENT_ASTEL_ACTIVE_SEARCH_COUNT')

          // Only set loading to false if there are no active searches
          if (state.activeSearchCount === 0) {
            commit('SET_ASTEL_CONTACTS_LOADING', false)
            commit('SET_ASTEL_COMPANIES_LOADING', false)
          }

          if (hash === state.searchHash) {
            commit('SET_ASTEL_CURRENT_SEARCH_CANCEL_TOKEN', null)
          }
        })
    })
  },

  LOAD_ASTEL_CURRENT_SEARCH_AND_FETCH_FIELDS({ commit, dispatch }, { startupId, search }) {
    return new Promise((resolve, reject) => {
      commit('SET_CURRENT_SEARCH', search);
      commit('SET_ASTEL_BREAKDOWN_FIELD', search.breakdown);
      commit('SET_ASTEL_IS_BEST_MATCHES_SEARCH', search.is_best_match);
      commit('SET_ASTEL_IS_CAREER_MATCHES_SEARCH', search.is_career_match);

      let breakdown = null;

      if(search.breakdown){
        breakdown = {
          custom_groups: [],
          default: true,
          field_name: search.breakdown?.id
        };
      }
      dispatch('LOAD_ASTEL_MATCH_FIELDS', { startupId, breakdown })
        .then(() => {
          commit('SET_ASTEL_MATCH_GROUP', search.selected_match_group);
          resolve();
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },

  ASTEL_SEARCH_CONTACTS ({ commit, dispatch, state }, { form, params, startupId, country }) {
    const hash = SparkMD5.hash(JSON.stringify(form) + JSON.stringify(params) + startupId)
    commit('SET_ASTEL_CONTACTS_HASH', hash)
    commit('SET_ASTEL_CONTACTS_LOADING', true)
    commit('SET_ASTEL_CONTACTS_LOADED', false)
    const url = country
      ? `${import.meta.env.VITE_API_URL}contacts/startup/astel/${startupId}/?country=${country.code}`
      : `${import.meta.env.VITE_API_URL}contacts/startup/astel/${startupId}/`;
    return new Promise((resolve, reject) => {
      axios.get(
        url,
        form,
        {
          params,
          paramsSerializer: {
            indexes: null
          }
        }
      )
        .then((response) => {
          if (hash === state.contactsHash) {
            commit('SET_ASTEL_CONTACTS', response.data)
            commit('SET_ASTEL_CONTACTS_LOADING', false)
            commit('SET_ASTEL_CONTACTS_LOADED', true)
          }
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  SUBMIT_ASTEL_NETWORK_ACCESS_REQUEST ({ commit, dispatch, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}requests/`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_ASTEL_SEARCH_FIELDS ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}options/search_fields/`)
        .then((response) => {
          commit('SET_ASTEL_SEARCH_FIELDS', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_ASTEL_MATCH_FIELDS ({ commit, state, dispatch }, { startupId,  breakdown = null }) {
    return new Promise((resolve, reject) => {
      if (!breakdown) {
        axios.post(`${import.meta.env.VITE_API_URL}startups/${startupId}/match_fields/`)
        .then((response) => {
          commit('SET_ASTEL_MATCH_FIELDS', response.data);
          commit('SET_ASTEL_MATCH_GROUP', response?.data[0]?.name || '');
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
      } else {
        // breakdown = state.breakdownOptions
        commit('SET_ASTEL_BREAKDOWN_OPTIONS', breakdown)
        const data = {
          breakdownby: JSON.parse(JSON.stringify(breakdown))
        }
        data.breakdownby.custom_groups = data.breakdownby.custom_groups.map((g) => {
          g[breakdown.field_name] = g[breakdown.field_name].map((v) => v.id ? v.id : v)
          return g
        })
        axios.post(`${import.meta.env.VITE_API_URL}startups/${startupId}/match_fields/`, data)
        .then((response) => {
          commit('SET_ASTEL_MATCH_FIELDS', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
      }
    })
  },

  SEARCH_ASTEL_CITIES ({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}options/cities/`, { params })
        .then((response) => {
          commit('SET_ASTEL_CITIES', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

}

const getters = {}

const mutations = {
  SET_SELECTED_ASTEL_COUNTRY (state, payload) {
    state.selectedCountry = payload
  },
  SET_ASTEL_CONTACTS (state, payload) {
    state.contacts = payload
  },
  SET_ASTEL_SEARCH_HASH (state, payload) {
    state.searchHash = payload
  },
  SET_ASTEL_COMPANIES (state, payload) {
    state.companies = payload
  },
  SET_ASTEL_COMPANIES_HASH (state, payload) {
    state.companiesHash = payload
  },
  SET_ASTEL_COMPANIES_LOADING (state, payload) {
    state.companiesLoading = payload
  },
  SET_ASTEL_COMPANIES_LOADED (state, payload) {
    state.companiesLoaded = payload
  },
  SET_ASTEL_CONTACTS_HASH (state, payload) {
    state.contactsHash = payload
  },
  SET_ASTEL_ASTEL_CONTACTS_HASH (state, payload) {
    state.contactsHash = payload
  },
  SET_ASTEL_CONTACTS_LOADING (state, payload) {
    state.contactsLoading = payload
  },
  SET_ASTEL_CONTACTS_LOADED (state, payload) {
    state.contactsLoaded = payload
  },
  SET_ASTEL_SEARCHES (state, payload) {
    state.searches = payload
  },
  SET_ASTEL_CURRENT_SEARCH (state, payload) {
    state.currentSearch = payload
  },
  SET_ASTEL_INVESTOR_ASTEL_SEARCH (state, payload) {
    state.investorAstelSearch = payload
  },
  SET_ASTEL_INVESTOR_TYPES (state, payload) {
    state.investorTypes = payload
  },
  SET_ASTEL_INVESTMENT_STAGES (state, payload) {
    state.investmentStages = payload
  },
  SET_ASTEL_ONELINER(state, payload) {
    state.oneLiner = payload
  },
  SET_ASTEL_CITIES (state, payload) {
    state.cities = payload
  },
  SET_ASTEL_COUNTRIES (state, payload) {
    state.countries = payload
  },
  SET_ASTAL_COUNTRIES (state, payload) {
    state.countries = payload
  },
  SET_ASTEL_TITLES (state, payload) {
    state.titles = payload
  },
  SET_ASTEL_REGIONS (state, payload) {
    state.regions = payload
  },
  SET_ASTEL_CONTACT_TYPES (state, payload) {
    state.contactTypes = payload
  },
  SET_ASTEL_SEARCH_FIELDS (state, payload) {
    state.searchFields = payload
  },
  SET_ASTEL_SEARCH_FORM (state, payload) {
    state.searchForm = payload
  },
  SET_ASTEL_SEARCH_OPTIONS (state, payload) {
    state.searchOptions = payload
  },
  SET_ASTEL_BREAKDOWN_OPTIONS (state, payload) {
    state.breakdownOptions = payload
  },
  RESET_ASTEL_BREAKDOWN_OPTIONS (state) {
    state.breakdownOptions = {
      field_name: 'investment_countries',
      default: true,
      custom_groups: []
    }
  },
  SET_ASTEL_MATCH_FIELDS (state, payload) {
    state.matchFields = payload
  },
  SET_ASTEL_MATCH_GROUP (state, payload) {
    state.matchGroup = payload
  },
  SET_ASTEL_CURRENT_SEARCH_CANCEL_TOKEN(state, payload) {
    state.currentSearchCancelToken = payload
  },
  INCREMENT_ASTEL_ACTIVE_SEARCH_COUNT(state) {
    state.activeSearchCount++
  },
  DECREMENT_ASTEL_ACTIVE_SEARCH_COUNT(state) {
    state.activeSearchCount = Math.max(0, state.activeSearchCount - 1)
  },
  SET_ARE_WARM_INTRODUCTIONS(state, payload) {
    state.areWarmIntroductions = payload
  },
  SET_ASTEL_IS_CAREER_MATCHES_SEARCH (state, payload) {
    state.isCareerMatchesSearch = payload
  },
  SET_ASTEL_IS_BEST_MATCHES_SEARCH (state, payload) {
    state.isBestMatchesSearch = payload
  },
  CLEAR_ASTEL_INVESTORS (state) {
    state.companies = null
    state.contacts = null
    state.searches = null
    state.investorTypes = []
    state.investmentStages = []
    state.countries = []
    state.contactTypes = []
    state.searchForm = []
    state.searchOptions = []
    state.breakdownOptions = {
      field_name: 'investment_countries',
      default: true,
      custom_groups: []
    }
    state.matchFields = []
    state.matchGroup = ''
    state.jobs = []
    state.selectedCountry = null
  },
  SET_ASTEL_BREAKDOWN_FIELD (state, payload) {
    state.breakdownField = payload
  },
  SET_ASTEL_BREAKDOWN_TIER (state, payload){
    state.breakdownTier = payload
  },
  RESET_ASTEL_ACTIVE_SEARCH_COUNT(state){
    state.activeSearchCount = 0
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
