<template>
  <header>
    <div class="header-loader" v-if="loaderActive">
      <div class="header-loader-inner" :style="`width: ${this.loaderProgress}%`"></div>
    </div>
    <div class="navbar-custom topnav-navbar">
      <div class="container-fluid d-flex justify-content-between">
        <div class="d-flex">
          <router-link :to="{ name: 'home' }" class="topnav-logo">
            <template v-if="siteCompany && siteCompany.company_logo">
              <span class="topnav-logo-lg">
                <img :src="siteCompany.company_logo" alt="" height="40">
              </span>
              <span class="topnav-logo-sm">
                <img :src="siteCompany.company_logo" alt="" height="16">
              </span>
            </template>
            <template v-else>
              <span class="topnav-logo-lg">
                <img src="/static/images/astel_logo.png" alt="" height="40">
              </span>
              <span class="topnav-logo-sm">
                <img src="/static/images/astel_logo.png" alt="" height="16">
              </span>
            </template>
          </router-link>
          <div class="d-flex align-items-center ms-2 large" v-if="startup">
              <span class="nav-link pt-1">
              Company:
              <router-link :to="{ name: 'startup_profile' }">
                <span class="align-middle d-none d-lg-inline-block fw-bold">{{ startup.name }}</span>
              </router-link>
              <router-link :to="{ name: 'startups_selector' }" v-if="user.is_staff">
                <i class="mdi mdi-chevron-down d-none d-sm-inline-block align-middle"></i>
              </router-link>
              </span>
          </div>
        </div>
        <ul class="topbar-menu d-flex align-items-center mb-0" style="list-style: none;">
          <job-monitor></job-monitor>
          <li class="dropdown">
            <a class="nav-link dropdown-toggle arrow-none nav-user px-2" data-bs-toggle="dropdown" href="#" role="button"
              aria-haspopup="false" aria-expanded="false">
              <span class="account-user-avatar">
                <img src="/static/images/profile.png" alt="user-image" width="32" class="rounded-circle">
              </span>
              <span class="d-lg-flex flex-column d-none">
                <h5 class="my-0">{{ user.first_name }}</h5>
                <h6 class="my-0 fw-normal">{{ user.email }}</h6>
              </span>
            </a>
            <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated profile-dropdown" style="">
              <a href="#" class="dropdown-item" @click.prevent="goAccount">
                <i class="uil uil-user me-1"></i>
                <span>Account</span>
              </a>
              <!-- <a href="#" class="dropdown-item" @click.prevent="goStartup">
                <i class="uil uil-bag me-1"></i>
                <span>Startup</span>
              </a> -->
              <template v-if="user && user.is_staff">
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item" @click.prevent="goUpload">
                  <i class="uil uil-chart me-1"></i>
                  <span>Upload data</span>
                </a>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item" @click.prevent="goEmailAccounts">
                  <i class="uil uil-envelopes me-1"></i>
                  <span>Email Accounts</span>
                </a>
                <a href="#" class="dropdown-item" @click.prevent="goFeed">
                  <i class="uil uil-newspaper me-1"></i>
                  <span>Feed</span>
                </a>
                <a href="#" class="dropdown-item" @click.prevent="goSettings">
                  <i class="uil uil-cog me-1"></i>
                  <span>GPT Settings</span>
                </a>
                <a href="#" class="dropdown-item" @click.prevent="goUsage">
                  <i class="uil uil-chart me-1"></i>
                  <span>Usage</span>
                </a>
                <a href="#" class="dropdown-item" @click.prevent="goInvite">
                  <i class="uil uil-users-alt me-1"></i>
                  <span v-if="siteCompany.is_whitelabel">Manage Company Invitation</span>
                  <span v-else>Manage Startup Invitations</span>
                </a>
                <a href="#" class="dropdown-item" @click.prevent="goEngagementCall">
                  <i class="uil uil-phone me-1"></i>
                  <span>Engagement Call Info</span>
                </a>
                <div class="dropdown-divider"></div>
                <a href="/static/verse-ext.zip" class="dropdown-item">
                  <i class="uil uil-puzzle-piece me-1"></i>
                  <span>Download Chrome Plugin</span>
                </a>
              </template>
              <div class="dropdown-divider"></div>
              <a href="#" class="dropdown-item" @click.prevent="logout">
                <i class="mdi mdi-logout me-1"></i>
                <span>Logout</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'

import JobMonitor from '@/components/Includes/JobMonitor.vue'

export default {
  name: 'app-header',

  components: {
    'job-monitor': JobMonitor
  },

  data () {
    return {
    }
  },

  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn,
      startup: state => state.startups.current,
      user: state => state.auth.user,
      siteCompany: state => state.auth.siteCompany,
      loaderActive: state => state.loader.active,
      loaderProgress: state => state.loader.progress,
    })
  },
  mounted () {
  },
  methods: {
    goAccount() {
      this.$router.push({ name: 'account' })
    },
    goUpload() {
      this.$router.push({ name: 'upload' })
    },
    goStartup() {
      this.$router.push({ name: 'me_startup' })
    },
    goEmailAccounts() {
      this.$router.push({ name: 'email_accounts' })
    },
    goFeed() {
      this.$router.push({ name: 'feed' })
    },
    goSettings() {
      this.$router.push({ name: 'gpt-prompts' })
    },
    goUsage() {
      this.$router.push({ name: 'usage' })
    },
    goUpload() {
      this.$router.push({ name: 'upload' })
    },
    goInvite() {
      this.$router.push({ name: 'invitations' })
    },
    goEngagementCall() {
      this.$router.push({ name: 'engagement-call-info' })
    },
    logout () {
      this.$router.push({ name: 'logout' })
    }
  }
}
</script>
